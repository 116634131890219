import Logo from "public/assets/SVG/logo.svg"
import { useEffect } from "react"
import { gsap, Power3 } from "gsap"

const Loading = ({ isLoading }) => {
	function shakeScene() {
		const tlAnim = gsap.timeline({ repeat: 1, repeatDelay: 0 })
		tlAnim
			.to("#logo_svg__logo", {
				rotation: "+=5",
				x: "+=3",
				ease: "none",
				duration: 0.1,
			})
			.to("#logo_svg__logo", {
				rotation: "-=5",
				x: "-=3",
				ease: "none",
				duration: 0.1,
			})

		return tlAnim
	}

	function zoomLetters() {
		const tlAnim = gsap.timeline()
		tlAnim
			.to("#logo_svg__letter-H", {
				scale: 1.6,
				transformOrigin: "center center",
				duration: 0.2,
			})
			.to("#logo_svg__letter-H", {
				scale: 1,
				transformOrigin: "center center",
				duration: 0.2,
			})
			.to("#logo_svg__letter-F", {
				scale: 1.6,
				transformOrigin: "center center",
				duration: 0.2,
			})
			.to("#logo_svg__letter-F", {
				scale: 1,
				transformOrigin: "center center",
				duration: 0.2,
			})
			.to("#logo_svg__letter-C", {
				scale: 1.6,
				transformOrigin: "center center",
				duration: 0.2,
			})
			.to("#logo_svg__letter-C", {
				scale: 1,
				transformOrigin: "center center",
				duration: 0.2,
			})

		return tlAnim
	}

	function slideUp() {
		const tlAnim = gsap.timeline()
		tlAnim.to("#loading", 2, {
			y: "-100vh",
			ease: Power3.easeOut,
		})

		return tlAnim
	}

	function hide() {
		const tlAnim = gsap.timeline()
		tlAnim.to("#loading", {
			autoAlpha: 0,
		})

		return tlAnim
	}

	useEffect(() => {
		const element = document.getElementById("logo_svg__logo")
		let masterTimeline: gsap.core.Timeline

		if (element) {
			masterTimeline = gsap.timeline({ repeat: -1, repeatDelay: 0.5 })
			masterTimeline.add(shakeScene())
			masterTimeline.add(zoomLetters())
			masterTimeline.add(slideUp())
			masterTimeline.play()
		}

		return () => {
			if (masterTimeline) masterTimeline.kill()
		}
	}, [])

	useEffect(() => {
		const element = document.getElementById("loading")
		let masterTimeline: gsap.core.Timeline

		if (element && !isLoading) {
			masterTimeline = gsap.timeline()
			masterTimeline.add(slideUp())
			masterTimeline.add(hide())

			masterTimeline.play()
		}

		return () => {
			if (masterTimeline) masterTimeline.kill()
		}
	}, [isLoading])

	return (
		<div
			className="fixed top-0 bottom-0 left-0 right-0 bg-[url('/game/grayscalemap.png')] bg-cover bg-center bg-fixed bg-no-repeat"
			id="loading"
		>
			<Logo className="w-64 mx-auto md:w-80" />
		</div>
	)
}

export default Loading
