import { LinkButton } from "@hfc/shared/components/Button"
import Image from "next/image"

const team: {
	name: string
	mainRole: string
	role: string
	avatar: string
	placeHolder: string
	summary: string
	smm?: {
		twitterUrl?: string
		linkedinUrl?: string
	}
}[] = [
	//Founder

	{
		name: "John the Farmer",
		mainRole: "Founder",
		role: "Founder",
		avatar: "/images/team/john.png",
		placeHolder: "Brand & Community",
		summary: "",
		smm: {
			twitterUrl: "https://twitter.com/hfcjohn_",
		},
	},
	{
		name: "Jack the Farmer",
		mainRole: "Founder",
		role: "Art Director",
		avatar: "/images/team/jack.jpg",
		placeHolder: "Art Director",
		summary: "",
		smm: {
			twitterUrl: "https://twitter.com/hfcjack",
		},
	},

	{
		name: "Mastermice",
		mainRole: "Founder",
		role: "Founder",
		avatar: "/images/team/MASTERMICEpfp.png",
		placeHolder: "GameFi Developer",
		summary: "",
		smm: {},
	},

	// Executives

	{
		name: "CrypTopeng",
		mainRole: "Executives",
		role: "Chief Executive Officer",
		avatar: "/images/team/CrypTopeng.png",
		placeHolder: "Chief Executive Officer, Lead Dev",
		summary:
			"Content creator and software engineer with 7+ years of experience. Passionate about innovation, marketing, UI/UX and web3. A crypto enthusiast since 2017 advocating for web3 mass adoption in the Philippines.",
		smm: {
			twitterUrl: "https://twitter.com/CrypTopeng_NFT",
			linkedinUrl: "https://www.linkedin.com/in/theoroque/",
		},
	},

	{
		name: "Nathaniel",
		mainRole: "Executives",
		role: "Chief Marketing Officer",
		avatar: "/images/team/nathaniel.png",
		placeHolder: "Chief Marketing Officer",
		summary:
			"A marketing graduate and a successful owner of multiple food and beverages businesses with a passion peoples management and community building. NFT enthusiast since early 2021.",
		smm: {
			twitterUrl: "https://twitter.com/Nathaniel2118",
		},
	},

	{
		name: "Twopaps",
		mainRole: "Executives",
		role: "Chief Product Officer",
		avatar: "/images/team/twopaps.png",
		placeHolder: "Chief Product Officer",
		summary:
			"Professional product practitioner for numerous startups and corporations who is continuously building, measuring, and learning. Blockchain and FinTech advocate since 2017.",
		smm: {
			linkedinUrl: "https://www.linkedin.com/in/thejohnpinto/",
		},
	},

	{
		name: "Chaz",
		mainRole: "Executives",
		role: "Chief Creatives Officer",
		avatar: "/images/team/Chaz-HFC.png",
		placeHolder: "Chief Creative Officer",
		summary:
			"A nine-to-fiver in the Architecture Industry for 6+ years. Somehow managed an unassisted touchdown on web3 in 2022, and is now creating and flipping jpegs for a living.",
		smm: {
			twitterUrl: "https://twitter.com/arcvda_eth",
			linkedinUrl: "https://www.linkedin.com/in/arcvda/",
		},
	},

	// Development Team

	{
		name: "ras",
		mainRole: "Team Members",
		role: "Illustrator",
		avatar: "/images/team/Ras.png",
		placeHolder: "Illustrator",
		summary:
			"Digital artist and a soon-to-be architect. A crypto enthusiast who wants to explore more opportunities in web3. Likes cats.",
		smm: {
			twitterUrl: "https://twitter.com/eth_ras",
		},
	},

	// Head Moderator

	{
		name: "Bones",
		mainRole: "Team Members",
		role: "Head Moderator",
		avatar: "/images/team/bones.jpg",
		placeHolder: "Head Moderator",
		summary:
			"Computer Science Graduate. Passionate in helping and guiding people. Crypto trader, art lover and a web 3 explorer.",
		smm: {
			twitterUrl: "https://twitter.com/BigBoneHFC",
		},
	},

	{
		name: "BENG.eth",
		mainRole: "Team Members",
		role: "Advisor",
		avatar: "/images/team/beng-mutant.jpg",
		placeHolder: "Advisor",
		summary:
			"Real estate and traditional businessman who has ventured and continuously look to find trends in the crypto and NFT space since 2014.",
		smm: {
			twitterUrl: "https://twitter.com/thinkingnft",
		},
	},
]

const Team = () => {
	return (
		<section
			id="section-team"
			className="py-12 bg-[url('/images/team/team-background.svg')] bg-cover bg-center bg-fixed bg-no-repeat md:pt-0 md:pb-24"
		>
			<div>
				<div className="px-4 py-12 mx-auto text-center bg max-w-[1500px] sm:px-6 lg:px-8 lg:py-24">
					<div className="space-y-10 ">
						<div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-4xl">
							<h2 className="z-40 px-2 py-4 text-2xl text-center text-white uppercase border-[#24180D] border-[5px] bg-[#a27542] rounded-3xl md:text-6xl">
								The Good &lsquo;Ol Team
							</h2>
						</div>

						<h1 className="mb-8 text-5xl font-extrabold tracking-tight text-center text-farm-yellow">
							Founders
						</h1>
						<div className="flex flex-wrap justify-center gap-12">
							{team
								.filter(
									(member) => member.mainRole === "Founder",
								)
								.map((member) => {
									return (
										<div key={member.name}>
											<Card member={member} />
										</div>
									)
								})}
						</div>

						<h1 className="mb-8 text-5xl font-extrabold tracking-tight text-center text-farm-yellow">
							Executives
						</h1>
						<div className="flex flex-wrap justify-center gap-12">
							{team
								.filter(
									(member) =>
										member.mainRole === "Executives",
								)
								.map((member) => {
									return (
										<div key={member.name}>
											<Card member={member} />
										</div>
									)
								})}
						</div>

						<h1 className="mb-8 text-5xl font-extrabold tracking-tight text-center text-farm-yellow">
							Team Members
						</h1>
						<div className="flex flex-wrap justify-center gap-12">
							{team
								.filter(
									(member) =>
										member.mainRole === "Team Members",
								)
								.map((member) => {
									return (
										<div key={member.name}>
											<Card member={member} />
										</div>
									)
								})}
						</div>

						{/* <div className="flex flex-wrap justify-center gap-12 ">
							{team
								.filter(
									(member) => member.mainRole === "Others",
								)
								.map((member) => {
									return (
										<div key={member.name}>
											<h1 className="mb-8 text-5xl font-extrabold tracking-tight text-center text-farm-yellow sm:text-3xl lg:text-4xl">
												{member.role}
											</h1>
											<Card member={member} />
										</div>
									)
								})}
						</div> */}
					</div>
				</div>
			</div>
		</section>
	)
}

const Card = ({ member }) => {
	const { name, role, placeHolder, summary, smm = {}, avatar } = member
	const { twitterUrl, linkedinUrl } = smm
	return (
		<div className="relative flex justify-center h-full md:flex-wrap">
			<div className="space-y-4 p-5 bg-white w-[320px] rounded-3xl ">
				<div className="relative w-40 h-40 mx-auto overflow-hidden border-4 rounded-3xl">
					<Image
						src={avatar}
						alt="farmer-pic"
						layout="fill"
						className="bg-cover"
						priority
					/>
				</div>
				<div className="pb-12 space-y-6">
					<div className="space-y-2 text-lg font-medium leading-5 text-black">
						<h3>{name}</h3>

						{placeHolder && (
							<p className="mt-2 text-farm-green">
								{placeHolder}
							</p>
						)}
						{summary && (
							<hr className="w-20 pb-4 mx-auto text-center" />
						)}
						<p className=" text-farm-green">{summary}</p>
					</div>
					<ul className="absolute left-0 right-0 flex justify-center space-x-5 bottom-4">
						{twitterUrl && (
							<li>
								<LinkButton
									variant="transparent"
									href={twitterUrl}
									target="_blank"
									className="text-farm-dark-grey"
									linkProps={{
										rel: "noopener noreferrer",
									}}
								>
									<svg
										className="w-5 h-5 hover:fill-farm-black"
										aria-hidden="true"
										fill="gray"
										viewBox="0 0 20 20"
									>
										<path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
									</svg>
								</LinkButton>
							</li>
						)}

						{linkedinUrl && (
							<li>
								<LinkButton
									variant="transparent"
									href={linkedinUrl}
									target="_blank"
									className="text-farm-dark-grey"
									linkProps={{
										rel: "noopener noreferrer",
									}}
								>
									<svg
										fill="gray"
										className="w-5 h-5 hover:fill-farm-black"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										aria-hidden="true"
									>
										<path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
									</svg>
								</LinkButton>
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Team
