import { LinkButton } from "@hfc/shared/components/Button"
import useSectionRoadmapAnimation from "./animations/useSectionRoadmapAnimation"
import RoadmapSVG from "public/images/roadmap-page/roadmap-2022.svg"
import Phase1 from "public/images/roadmap-page/january-march.svg"
import Phase2 from "public/images/roadmap-page/april-june.svg"
import Phase3 from "public/images/roadmap-page/whats-next.svg"

const Roadmap = () => {
	useSectionRoadmapAnimation()

	return (
		<section
			className="relative min-h-[150vh] max-w-screen py-14 z-40 overflow-auto border-y-[18px] border-y-black  text-black  "
			id="section-roadmap"
		>
			<h2 className="z-40 text-6xl text-center uppercase md:text-9xl text-farm-yellow">
				Roadmap
			</h2>
			<RoadmapSVG
				alt="Roadmap 2022"
				id="img-roadmap"
				className="h-full md:h-[150vh] w-full mx-auto my-auto z-40"
			/>

			<div className="z-40 flex flex-col items-start justify-center w-full px-4 py-10 md:flex-row h-ful md:px-40">
				<Phase1 className="py-4 md:py-0 md:px-4 roadmap-phase" />
				<Phase2 className="py-4 md:py-0 md:px-4 roadmap-phase" />
				<Phase3 className="py-4 md:py-0 md:px-4 roadmap-phase" />
			</div>

			<div className="flex mt-6 md:mt-24 md:mb-8">
				<LinkButton
					className="block px-10 py-6 mx-auto text-xl md:text-4xl"
					variant="secondary"
					href={`${
						process.env.NEXT_PUBLIC_WHITEPAPER_URL ??
						"https://docs.farmernft.com/howdy-paper-2.0"
					}`}
					linkProps={{ className: "block mx-auto" }}
				>
					View Howdy Paper
				</LinkButton>
			</div>
		</section>
	)
}

export default Roadmap
