import { gsap, Power1 } from "gsap"
import { useEffect } from "react"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import { useRouter } from "next/router"

import { isDesktop, isTablet } from "react-device-detect"

const useSectionHomeAnimation = () => {
	gsap.registerPlugin(ScrollTrigger)
	const router = useRouter()

	function parallaxBackground() {
		gsap.set("#img-home-background", {
			scale: 1.5,
		})
		gsap.to("#img-home-background", {
			scrollTrigger: {
				trigger: "#img-home-background",
				scrub: 1,
				start: "top top",
			},
			scale: 1,
		})
	}

	function parallaxHowdy() {
		if (isDesktop || isTablet) {
			gsap.set("#img-home-texts", {
				y: 50,
			})
			gsap.to("#img-home-texts", {
				scrollTrigger: {
					trigger: "#img-home-texts",
					scrub: 1,
					start: "top top",
				},
				y: 900,
			})
		} else {
			gsap.set("#img-home-texts", {
				yPercent: -15,
				scale: 2,
			})
			gsap.to("#img-home-texts", {
				scrollTrigger: {
					trigger: "#section-home",
					scrub: 1,
					start: "top top",
				},
				yPercent: 100,
			})
		}
	}

	function parallaxMeadow() {
		if (isDesktop || isTablet) {
			gsap.to("#img-home-meadow", {
				scrollTrigger: {
					trigger: "#img-home-meadow",
					scrub: 1,
					start: "top top",
				},
				scale: 1.2,
			})
		} else {
			gsap.set("#img-home-meadow", {
				scale: 1,
				yPercent: 5,
			})
			gsap.to("#img-home-meadow", {
				scrollTrigger: {
					trigger: "#img-home-meadow",
					scrub: 1,
					start: "top top",
				},
				scale: 1.2,
			})
		}
	}

	function parallaxForeground() {
		if (isDesktop || isTablet) {
			gsap.set("#home-foreground_svg__foreground", {
				scale: 2.5,
				y: -1500,
			})
			gsap.to("#home-foreground_svg__foreground", {
				scrollTrigger: {
					trigger: "#home-foreground_svg__foreground",
					scrub: 1,
					start: "top top",
				},
				scale: 1,
				y: 0,
			})
		} else {
			gsap.set("#home-foreground_svg__foreground", {
				scale: 7,
				x: "-50%",
				y: -300,
			})
			gsap.to("#home-foreground_svg__foreground", {
				scrollTrigger: {
					trigger: "#home-foreground_svg__foreground",
					scrub: 1,
					start: "top top",
				},
				scale: 1,
				y: 250,
			})
		}
	}

	function parallaxFlying() {
		if (isDesktop || isTablet) {
			gsap.to("#img-home-clouds,#img-home-airdrop", {
				scrollTrigger: {
					trigger: "#img-home-clouds,#img-home-airdrop",
					scrub: 1,
					start: "top top",
				},
				scale: 1.2,
			})
		} else {
			gsap.set("#img-home-clouds,#img-home-airdrop", {
				scale: 1.2,
			})
			gsap.to("#img-home-clouds,#img-home-airdrop", {
				scrollTrigger: {
					trigger: "#img-home-clouds,#img-home-airdrop",
					scrub: 1,
					start: "top top",
				},
				scale: 5,
			})
		}
	}

	function navbarZoom() {
		gsap.to("#navbar", {
			scrollTrigger: {
				trigger: "#navbar",
				scrub: 1,
				start: "top top",
			},
			scale: 0.8,
			y: -25,
		})
	}

	function cloudsScene() {
		const anim = gsap.timeline({ repeat: Infinity, repeatDelay: 1 })
		anim.to("#img-home-clouds", {
			duration: 5,
			repeat: Infinity,
			yoyo: true,
			x: -50,
		})
		return anim
	}

	function howdyWiggle() {
		const anim = gsap.timeline({ repeat: Infinity, repeatDelay: 1.5 })
		anim.to("#img-home-texts", {
			duration: 0.2,
			scale: "+=0.05",
		})
			.to("#img-home-texts", {
				duration: 0.2,
				scale: "-=0.05",
				easeIn: Power1.easeIn,
			})
			.to("#img-home-texts", {
				duration: 0.2,
				scale: "+=0.05",
			})
			.to("#img-home-texts", {
				duration: 0.2,
				scale: "-=0.05",
				easeIn: Power1.easeIn,
			})
		return anim
	}

	function swingPotato() {
		const anim = gsap.timeline({
			repeat: Infinity,
			repeatDelay: 1,
			yoyo: true,
		})
		const element = "#img-home-airdrop"
		anim.to(element, {
			duration: 5,
			left: 50,
			rotation: -5,
			ease: Power1.easeInOut,
		})
			.to(element, {
				duration: 5,
				left: -50,
				rotation: 5,
				ease: Power1.easeInOut,
			})
			.to(element, {
				duration: 5,
				left: 0,
				rotation: 0,
				ease: Power1.easeInOut,
			})

		return anim
	}

	useEffect(() => {
		const id = "section-home"
		const element = document.getElementById(id)
		let masterTimeline: gsap.core.Timeline

		if (element) {
			masterTimeline = gsap.timeline({
				paused: true,
				repeat: Infinity,
				repeatDelay: 1,
			})
			masterTimeline.add(cloudsScene(), 0)
			masterTimeline.add(swingPotato(), 0)
			masterTimeline.add(howdyWiggle(), 0)

			masterTimeline.play()

			parallaxForeground()
			parallaxBackground()
			parallaxHowdy()
			parallaxMeadow()
			parallaxFlying()

			if (isDesktop || isTablet) navbarZoom()

			const signage = document.getElementById(
				"home-foreground_svg__fg-signage",
			)

			if (signage) {
				signage.addEventListener("click", () => {
					router.push("https://game.farmernft.com/")
				})
			}
		}
	}, [])
}

export default useSectionHomeAnimation
