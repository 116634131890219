import useSectionFarmEcosystemAnimation from "./animations/useSectionFarmEcosystemAnimation"
import FarmEcosystemSVG from "public/images/ecosystem-page/game-ecosystem-board.svg"

const FarmEcosystem = () => {
	useSectionFarmEcosystemAnimation()

	return (
		<section
			className="relative pt-12 min-h-[100vh] max-w-screen z-30 text-black bg-[url('/images/ecosystem-page/game-ecosystem-background.svg')] bg-cover bg-center bg-fixed bg-no-repeat"
			id="section-farmecosystem"
		>
			<h2 className="z-30 flex items-center justify-center pt-8 text-2xl text-center uppercase md:text-8xl text-farm-yellow">
				Farm Ecosystem
			</h2>

			<FarmEcosystemSVG className="w-[130vw] md:w-[100vw]" />
		</section>
	)
}

export default FarmEcosystem
