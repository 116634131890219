import React from "react"

const parnters: {
	name: string
	logo: string
	twitterUrl?: string
}[] = [
	{
		name: "Dead Game Guild",
		logo: "/images/investor/deadgameguild.jpg",
	},
	{
		name: "Ape Army Guild",
		logo: "/images/investor/apearmy.png",
	},
	{
		name: "Elpads",
		logo: "/images/investor/elpdas.png",
	},
	{
		name: "1% Guild Gaming",
		logo: "/images/investor/1percent.jpg",
	},
]

const Investors = () => {
	return (
		<section
			id="partners"
			className="py-16 md:py-10 relative text-white bg-[#4b301c] border-4 border-black"
		>
			<div className="absolute w-10 h-10 px-2 py-2 mx-5 my-5 bg-gray-500 border-4 border-black rounded-full translate-x-2/4 "></div>
			<div className="absolute w-10 h-10 px-2 py-2 mx-5 my-5 bg-gray-500 border-4 border-black rounded-full translate-x-2/4 right-10 "></div>
			<div className="absolute translate-x-2/4 bottom-[1%] my-10 mx-5 px-2 py-2 border-4 border-black bg-gray-500 rounded-full h-10 w-10 "></div>
			<div className="absolute translate-x-2/4 right-10 bottom-[1%] my-10 mx-5 px-2 py-2 border-4 border-black bg-gray-500 rounded-full h-10 w-10 "></div>

			<div className="px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:px-8 lg:py-12">
				<div className="space-y-16">
					<div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4lg:max-w-5xl">
						<h2 className="text-5xl font-extrabold tracking-tight stroke-2 sm:text-5xl mb-18 text-upper stroke-black">
							Early Investors
						</h2>
					</div>

					<ul className="flex flex-wrap justify-center gap-12">
						{parnters.map((partner, i) => {
							const { name, logo, twitterUrl = "/" } = partner

							return (
								<li
									key={name}
									className={"md:space-y-6  mx-auto"}
								>
									<div className="space-y-6 md:space-y-6">
										<img
											className="bg-white mx-auto h-40 w-40 rounded-[30px] xl:w-56 xl:h-56 border-4 border-farm-black bg-[#a27542]"
											src={logo}
											alt={name + " logo"}
										/>
										<div className="space-y-6 sm:space-y-2">
											<div className="space-y-1 text-lg font-medium leading-5">
												<h3>{name}</h3>
												{/* <p className="mt-4">{name}</p> */}
											</div>
										</div>
									</div>
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		</section>
	)
}

export default Investors
