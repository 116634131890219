import { gsap } from "gsap"
import { useEffect } from "react"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import { isDesktop, isTablet } from "react-device-detect"

const useSectionFarmEcosystemAnimation = () => {
	gsap.registerPlugin(ScrollTrigger)

	function parallaxImage() {
		const boardId = "#game-ecosystem-board_svg__game-ecosystem-board_svg"

		if (isDesktop || isTablet) {
			gsap.set(boardId, {
				scale: 3,
				x: "-50vw",
			})
			gsap.to(boardId, {
				scrollTrigger: {
					trigger: "#section-farmecosystem",
					start: "top top",
					scrub: 1,
					pin: true,
				},
				scale: 1,
				x: "0",
			})
		} else {
			gsap.set(boardId, {
				scale: 4,
				y: "30vh",
				x: "-200vw",
			})
			gsap.to(boardId, {
				scrollTrigger: {
					trigger: "#section-farmecosystem",
					start: "top top",
					scrub: 1,
					pin: true,
				},
				x: "30vw",
			})
		}
	}

	useEffect(() => {
		const id = "section-nft"
		const element = document.getElementById(id)
		const mapDom = document.getElementById(
			"game-ecosystem-board_svg__game-ecosystem-board_svg",
		)

		if (element && mapDom) {
			parallaxImage()
		}
	}, [])
}

export default useSectionFarmEcosystemAnimation
