import Image from "next/image"
import useSectionHomeAnimation from "./animations/useSectionHomeAnimation"
import Foreground from "public/images/home-page/home-foreground.svg"

const SectionHome = () => {
	useSectionHomeAnimation()

	return (
		<section
			id="section-home"
			className="relative w-full h-screen overflow-hidden"
		>
			<Image
				src="/images/home-page/home-background.svg"
				alt="home-background"
				layout="fill"
				className="top-0 left-0 object-cover w-full h-full"
				id="img-home-background"
			/>
			<Image
				src="/images/home-page/home-sun.svg"
				alt="home-foreground"
				layout="fill"
				className="md:object-cover sm:object-fill"
				id="img-home-sun"
			/>
			<Image
				src="/images/home-page/home-clouds.svg"
				alt="home-clouds"
				width="100%"
				height="100%"
				layout="fill"
				className="object-fill"
				id="img-home-clouds"
			/>
			<Image
				src="/images/home-page/home-airdrop.svg"
				alt="home-airdrop"
				layout="fill"
				className="md:object-cover sm:object-fill"
				id="img-home-airdrop"
			/>
			<Image
				src="/images/home-page/home-texts.svg"
				alt="home-texts"
				layout="fill"
				className="object-fill md:object-cover"
				id="img-home-texts"
			/>
			<Image
				src="/images/home-page/home-meadow.svg"
				alt="home-meadow"
				layout="fill"
				className="object-cover object-top"
				id="img-home-meadow"
			/>
			<Foreground className="absolute bottom-0" />
		</section>
	)
}

export default SectionHome
