import { gsap, Power1 } from "gsap"
import { useEffect } from "react"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

import { isDesktop, isTablet } from "react-device-detect"

const useSectionNFTAnimation = () => {
	gsap.registerPlugin(ScrollTrigger)

	function parallaxText() {
		if (isDesktop || isTablet) {
			gsap.set("#nft-3000_svg__nft-3000", {
				y: "-40vh",
				scale: 0.8,
			})
			gsap.to("#nft-3000_svg__nft-3000", {
				scrollTrigger: {
					trigger: "#section-nft",
					start: "top bottom",
					end: "top center",
					scrub: 1,
				},
				y: "5vh",
				scale: 0.7,
			})
		} else {
			gsap.set("#nft-3000_svg__nft-3000", {
				y: "-25vh",
				scale: 1,
			})
			gsap.to("#nft-3000_svg__nft-3000", {
				scrollTrigger: {
					trigger: "#section-nft",
					start: "top bottom",
					end: "top center",
					scrub: 1,
				},
				y: "5vh",
				scale: 0.9,
			})
		}
	}

	function parallaxFarmers() {
		gsap.set("#nft-farmers-top", {
			x: "100%",
		})
		gsap.set("#nft-farmers-bottom", {
			x: "-100%",
		})
		gsap.to("#nft-farmers-top, #nft-farmers-bottom", {
			scrollTrigger: {
				trigger: "#section-nft",
				start: "top center",
				end: "top top",
				scrub: 1,
			},
			x: 0,
		})
	}

	useEffect(() => {
		const id = "section-nft"
		const element = document.getElementById(id)

		if (element) {
			parallaxText()
			// parallaxBackground()
			parallaxFarmers()
		}
	}, [])
}

export default useSectionNFTAnimation
