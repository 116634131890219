import { useEffect, useState } from "react"

const useBGM = (url) => {
	const [audio, setAudio] = useState(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const toggle = () => setIsPlaying(!isPlaying)

	useEffect(() => {
		if (typeof Audio !== "undefined" && audio !== null) {
			if (isPlaying) {
				audio.loop = true
				audio.volume = 0.1
				audio.play()
			} else {
				audio.pause()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlaying])

	useEffect(() => {
		setAudio(new Audio(url))
	}, [])

	return { isPlaying, toggle }
}

export default useBGM
