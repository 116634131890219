import Image from "next/image"
import useSectionNFTAnimation from "./animations/useSectionNFTAnimation"
import Farmer1 from "public/images/nft-page/farmer1.svg"
import Farmer2 from "public/images/nft-page/farmer2.svg"
import Farmer3 from "public/images/nft-page/farmer3.svg"
import Farmer4 from "public/images/nft-page/farmer4.svg"
import Farmer5 from "public/images/nft-page/farmer5.svg"
import Farmer6 from "public/images/nft-page/farmer6.svg"
import Farmer7 from "public/images/nft-page/farmer7.svg"
import Farmer8 from "public/images/nft-page/farmer8.svg"
import Farmer9 from "public/images/nft-page/farmer9.svg"
import Farmer10 from "public/images/nft-page/farmer10.svg"
import Nft3000 from "public/images/nft-page/nft-3000.svg"
import { isDesktop, isTablet } from "react-device-detect"
import { LinkButton } from "@hfc/shared/components/Button"
import OpenseaSVG from "public/assets/SVG/opensea-logo.svg"

const nftLinks = [
	{
		name: "Farmers",
		url: "https://opensea.io/collection/honest-farmer-club-farmers",
	},
	{
		name: "Farm Girls",
		url: "https://opensea.io/collection/honest-farmer-club-farm-girls",
	},
	{
		name: "Chickens",
		url: "https://opensea.io/collection/honest-farmer-club-chickens",
	},
	{
		name: "Pigs",
		url: "https://opensea.io/collection/honest-farmer-club-pigs",
	},
]

const SectionNFT = () => {
	useSectionNFTAnimation()

	return (
		<section
			id="section-nft"
			className="relative flex justify-center w-full h-[190vh] sm:h-[160vh] md:h-[150vh] border-b-[18px] bg-[url('/images/nft-page/full-home.svg')] bg-fixed bg-cover bg-center border-b-black px-20 z-40 mx-auto overflow-hidden
						 sectiong-bg"
		>
			<div
				id="nft-color-overlay"
				className="w-full h-full bg-[#09291C] bg-opacity-90 absolute top-0"
			>
				<Nft3000 className="z-30 max-h-40" />

				<div className="px-4 pt-[5vh]  lg:pt-[5vh] py-5 mt-40vh sm:mt-[5vh] mx-auto text-center bg max-w-[1500px] sm:px-6 lg:px-8 lg:py-2 z-10">
					<div
						className="flex flex-col h-[100vh] sm:flex-row sm:justify-center sm:h-[25vh] gap-6  z-10"
						id="nft-farmers-top"
					>
						<Farmer1 />
						<Farmer2 />
						<Farmer7 />
						{(isDesktop || isTablet) && (
							<>
								<Farmer4 />
								<Farmer10 />
							</>
						)}
					</div>
				</div>

				<div className="px-4 py-2 mx-auto text-center bg max-w-[1500px] max-h-[1940px] sm:px-6 lg:px-8 lg:py-2">
					<div
						className="flex flex-col h-[100vh] sm:flex-row sm:justify-center sm:h-[25vh] gap-6  z-50"
						id="nft-farmers-bottom"
					>
						<Farmer6 />
						<Farmer8 />
						<Farmer3 />
						{(isDesktop || isTablet) && (
							<>
								<Farmer9 />
								<Farmer5 />
							</>
						)}
					</div>
				</div>
				<div className="lg:px-[30vh]">
					<div className="px-5 py-12 text-lg leading-relaxed text-center md:px-10 lg:px-20 md:text-xl lg:text-3xl">
						3000 farmers, farm girls and other farm-themed NFTs
						building a sustainable and exclusive GameFi ecosystem on
						Polygon leveraging all the potential for memes,
						play-and-earn, edutech, token staking, liquidity
						farming, tokenomics and community empowerment - all
						built into a fun and interactive farm map.
					</div>
				</div>
				<div className="flex flex-wrap gap-4 justify-center w-full lg:px-[30vh]">
					{nftLinks.map(({ name, url }, key) => (
						<LinkButton
							key={key}
							href={url}
							className="relative flex flex-row items-center justify-center w-64 px-4 py-2 text-2xl bg-white border-4 border-solid rounded-full hover:text-farm-grey border-farm-black text-farm-black"
							target="_blank"
							variant="transparent"
						>
							<OpenseaSVG aria-hidden="true" /> {name}
						</LinkButton>
					))}
				</div>
			</div>

			<div className="w-screen md:w-4/6 h-[70%] bg-gradient-to-t from-[transparent] to-[#0a2102] bg-opacity-70 rounded-t-[25px] md:rounded-t-[75px] z-[-1]  flex items-end justify-center bottom-0 bottomText"></div>

			{/* Gallery */}
			{/* 
			<div className="z-20 flex flex-col justify-center w-full md:justify-center md:mt-10 lg:mt-40 gallery-layout">
				<div
					className="relative grid px-4 md:px-0 w-screen h-[1000px] my-5 sm:h-[1000px] md:grid-cols-5 gap-4 md:pr-0 lg:pr-40 max-h-[90vh] md:max-h-[25vh]"
					id="nft-farmers-top"
				>
					<Farmer1 />
					<Farmer2 />
					<Farmer7 />
					<Farmer4 />
					{(isDesktop || isTablet) && <Farmer10 />}
				</div>

				<div
					className="relative grid px-4 md:px-0 w-screen h-[1000px] my-5 sm:h-[1000px] md:grid-cols-5 gap-4 md:pr-0 lg:pr-40 max-h-[90vh] md:max-h-[25vh]"
					id="nft-farmers-bottom"
				>
					<Farmer6 />
					<Farmer8 />
					<Farmer3 />
					<Farmer9 />
					{(isDesktop || isTablet) && <Farmer5 />}
				</div>
			</div> */}

			{/* <div
				id="nft-container"
				className="w-full md:w-4/6 h-[90%] bg-gradient-to-t from-[transparent] to-[#0a2102] bg-opacity-70 rounded-t-[25px] md:rounded-t-[75px] z-10 absolute flex items-end justify-center bottom-0 bottomText"
			>
				<div className="px-4 text-xl leading-relaxed text-center md:px-20 md:text-3xl py-14">
					3000 farmers, farm girls and other farm-themed NFTs building
					a sustainable and exclusive GameFi ecosystem on Polygon
					leveraging all the potential for memes, play-and-earn,
					edutech, token staking, liquidity farming, tokenomics and
					community empowerment - all built into a fun and interactive
					farm map.
				</div>
			</div> */}
		</section>
	)
}

export default SectionNFT
