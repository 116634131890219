import PartnersSVG from "public/images/partners-page/partners.svg"
import PartnersForegroundSVG from "public/images/partners-page/partners-foreground.svg"
import { useEffect } from "react"

const SectionPartners = () => {
	useEffect(() => {
		const bitskwela = document.getElementById(
			"partners_svg__partner-bitskwela",
		)
		const adamant = document.getElementById("partners_svg__partner-adamant")

		bitskwela.addEventListener("click", () => {
			window.open("https://www.bitskwela.com/", "_blank")
		})
		adamant.addEventListener("click", () => {
			window.open("https://www.adamantmetanetwork.com/", "_blank")
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<section
			id="section-partners"
			className="py-8 md:py-16 relative overflow-hidden h-[50vh] md:h-[100vh] bg-[url('/images/partners-page/partners-background.svg')] bg-cover bg-left md:bg-center bg-fixed bg-no-repeat"
		>
			<div className="absolute bottom-0 w-[200vw] md:w-screen">
				<PartnersForegroundSVG className="" />
			</div>

			<div className="absolute bottom-0 right-0 w-[80vw] md:w-[75vw] mt-8">
				<PartnersSVG />
			</div>
		</section>
	)
}

export default SectionPartners
