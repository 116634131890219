import { NextPageContext } from "next"
import { promises as fs } from "fs"
import path from "path"
import React, { useEffect, useState } from "react"

import SectionFarmEcosystem from "components/SectionFarmEcosystem"
import SectionRoadmap from "components/SectionRoadmap"
import SectionNFT from "components/SectionNFT"
import SectionPartners from "components/SectionPartners"
import Rarity from "components/Rarity"
import SectionTeam from "components/SectionTeam"
import SectionHome from "components/SectionHome"
import Loading from "components/Loading"
import SEO from "@hfc/shared/components/SEO/SEO"

import { getRarity, Rarity as RarityI } from "./api/getRarity"

import useBGM from "utils/hooks/useBGM"
import Button, { LinkButton } from "@hfc/shared/components/Button"
import Sound from "public/btnSound.svg"
import SectionInvestors from "components/SectionInvestors"
import { FaDiscord, FaTwitter } from "react-icons/fa"

// TODO: move this to config
export async function getStaticProps(_context: NextPageContext) {
	const farmersDir = path.join(process.cwd(), "public/images/farmers")
	const leakImageUrls = await fs.readdir(farmersDir)
	const filteredLeakImageUrls = leakImageUrls.filter((url) =>
		url.endsWith(".jpg"),
	)
	const rarity = getRarity()

	return {
		props: { leakImageUrls: filteredLeakImageUrls, rarity },
	}
}

const HomePage = () => {
	const { isPlaying: isSoundOn, toggle } = useBGM("/BGM/HFC-bgm.wav")
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		// Change svg
		const soundOn = document.getElementById("btnSound_svg__sound-on")
		const soundOff = document.getElementById("btnSound_svg__sound-off")
		if (soundOn && soundOff) {
			if (isSoundOn) {
				soundOff.style.opacity = `0`
				soundOn.style.opacity = `100%`
			} else {
				soundOn.style.opacity = `0`
				soundOff.style.opacity = `100%`
			}
		}
	}, [isSoundOn])

	useEffect(() => {
		if (isLoading) {
			setTimeout(() => {
				setIsLoading(false)
			}, 3600)
		}
	}, [])

	return (
		<>
			<SEO asPath="/" title="Honest Farmer Club" appendDefaultTitle />
			<Loading isLoading={isLoading} />
			<SectionHome />
			<SectionNFT />
			<SectionFarmEcosystem />
			<SectionRoadmap />
			<SectionTeam />
			<SectionInvestors />
			<SectionPartners />

			<div className="fixed right-3 bottom-3 gap-0 z-[9999]">
				<div className="flex flex-col w-[auto] max-w-[4rem]">
					<LinkButton
						href={
							process.env.NEXT_PUBLIC_DISCORD_URL ??
							"https://discord.gg/dejEkgCQ44"
						}
						className="p-1 my-2 bg-white rounded-full hover:bg-farm-yellow"
						variant="transparent"
						target="_blank"
					>
						<FaDiscord
							aria-hidden="true"
							className="w-14 h-7  fill-[#4E62EE]"
						/>
					</LinkButton>
					<LinkButton
						href={
							process.env.NEXT_PUBLIC_TWITTER_URL ??
							"https://twitter.com/farmerNFT"
						}
						className="p-1 my-2 bg-white rounded-full hover:bg-farm-yellow"
						variant="transparent"
						target="_blank"
					>
						<FaTwitter
							aria-hidden="true"
							className="w-14 h-7  fill-[#1C96E8]"
						/>
					</LinkButton>
					<Button onClick={toggle} variant={"transparent"}>
						<Sound className="my-2 w-22 h-11" id="bgm-svg" />
					</Button>
				</div>
			</div>
		</>
	)
}

export default HomePage
