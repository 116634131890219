import { gsap, Power1 } from "gsap"
import { useEffect } from "react"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

import { isDesktop, isTablet } from "react-device-detect"

const useSectionRoadmapAnimation = () => {
	gsap.registerPlugin(ScrollTrigger)

	function parallaxRoadmap() {
		const start = isDesktop || isTablet ? "top top" : "top bottom"
		gsap.set("#img-roadmap", {
			scaleX: 0.2,
		})
		gsap.to("#img-roadmap", {
			scrollTrigger: {
				trigger: "#section-roadmap",
				start: "top top",
				end: "top bottom",
				scrub: 1,
			},
			scaleX: 1,
		})
	}

	useEffect(() => {
		const id = "section-roadmap"
		const element = document.getElementById(id)

		if (element) {
			parallaxRoadmap()
		}
	}, [])
}

export default useSectionRoadmapAnimation
